import Axios from "axios";
import {baseUrl} from "@configs/requestConfig";
import Notify from "@components/noty/notify";
import Cookies from "universal-cookie";
import {store} from "@store/storeConfig/store";
import {handleLogout} from "@store/actions/auth";


const initialHeaders = {
    ContentSource: "web",
    Authorization: '',
}

export default async function PostRequest(url, data, header = initialHeaders, locale = false) {
    if (typeof initialHeaders.Authorization === "undefined" || initialHeaders.Authorization === '' || initialHeaders.Authorization === null || initialHeaders.Authorization !== localStorage.getItem("token")) {
        initialHeaders.Authorization = localStorage.getItem("token");
    }


    const cookies = new Cookies();
    if (locale)
        header['locale'] = cookies.get('locale');
    else
        header['locale'] = cookies.get('language');
    const config = {
        headers: header,
    }
    try {
        console.log(baseUrl + url)
        return await Axios.post(baseUrl + url , data, config);
    } catch (err) {
        if (err.response) {
            if (err.response.status === 401) {
                store.dispatch(handleLogout())
            } else if (err.response.status === 500) {
                Notify('error', 'top-right', "خطای سرور");

            } else if (err.response.status === 422) {
                Object.keys(err.response.data.errors).map((item) => {
                    err.response.data.errors[item].map((item) => {
                        Notify('error', 'top-right', item);
                    })
                })
            } else if (err.response.status === 406) {
                Notify('error', 'top-right', "رکورد موجود نیست");
            } else if (err.response.status === 403) {
                Notify('error', 'top-right', err.response.data.message);
            }
        } else {
            Notify('error', 'top-right', "خطایی رخ داده");
            if (err.message) {
                console.log(err.message)
            }
        }
    }
}
